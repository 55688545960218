body {
    background-color: #f8f9fc !important;
    height: auto !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: auto !important;
}

.segment-shadow {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e3e6f0;
    border-radius: 8px;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
    padding: 20px;
    min-height: 300px;
    /*for loading*/
}

h3.ui.header .sub.header {
    font-size: 0.9rem !important;
}

.shadow {
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.issue-filter-bar {
    display: flex;
    flex-direction: row;
}

.issue-filter-bar>div {
    margin-right: 14px;
}


/* input search */

.issue-filter-bar>div:last-child {
    margin-right: 0px !important;
    margin-top: -1px;
}

table a {
    color: #12c0dc !important;
}

a:hover {
    color: #0699af !important;
}

.issue-card-container {
    margin-top: 10px;
    padding-left: 10px;
    /* overflow-x: scroll;
    height:80vh; */
}

.issue-nav-container {
    max-height: calc(100vh - 160px);
    min-height: calc(100vh - 160px);
}

.dashboard-feed {
    height: 460px;
    overflow: hidden;
}

.dashboard-feed:hover {
    overflow-y: scroll;
}

.dashboard-feed::-webkit-scrollbar {
    width: 2px;
}

.tester-search-container {
    max-height: calc(100vh);
    min-height: calc(100vh);
}

.issue-list-container {
    max-height: calc(100vh - 240px);
    min-height: calc(100vh - 240px);
    overflow-y: hidden;
}

.issue-list-container:hover {
    overflow-y: scroll;
    padding-right: 0px;
}

.issue-list-container::-webkit-scrollbar {
    width: 2px;
}

.issue-details-container-scroll::-webkit-scrollbar {
    width: 2px;
}

.comment-container::-webkit-scrollbar {
    width: 2px;
}

.issue-details-container {
    padding: 4px 20px 10px 20px;
}

.issue-details-container-scroll {
    max-height: calc(100vh - 228px);
    min-height: calc(100vh - 228px);
    margin-right: -40px !important;
    padding-right: 42px;
    overflow: hidden;
}

.issue-details-container-scroll:hover {
    overflow-y: scroll;
    padding-right: 40px;
}

.comment-container {
    max-height: calc(100vh - 470px);
    overflow: hidden;
    margin-right: -5px;
}

.comment-container:hover {
    overflow-y: scroll;
    margin-right: -2px;
}

.issue-card {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border-radius: 0 !important;
    min-height: 80px;
    padding-top: 10px;
    padding-left: 15px;
    box-shadow: none !important;
    border-bottom: 1px solid #dedede !important;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.issue-card:hover {
    background-color: #ccbfa817;
    border-left: 1px solid #00ada5;
    padding-left: 14px;
}

.issue-card .title {
    color: #474949;
    font-size: 14px;
    line-height: 16px;
}

.issue-card .testCycle {
    color: #474949;
    font-size: 10px;
    margin-top: -4px;
}

.active-issue {
    background-color: #9fa0a91f;
    border-left: 1px solid #00ada5;
}

.issue-card .date {
    color: #474949;
    font-size: 10px;
}

.issue-card .bottom {
    margin-top: 10px;
}

.ui.feed>.event>.content .extra.text {
    max-width: 100% !important;
}

.ui.feed>.event>.content .summary {
    font-size: 12px;
}

.padded-container {
    padding-left: 40px;
    padding-right: 40px;
}

.dashboard-issue:hover {
    background-color: #dedede !important;
    min-height: 120px;
    max-height: 120px;
}

.custom-image {
    /* filter: url(filters.svg#grayscale); Firefox 3.5+ */
    filter: none;
    -webkit-filter: grayscale(0);
    -webkit-transform: scale(1.01);
}


/*.custom-image:hover {*/


/*  filter: gray; !* IE5+ *!*/


/*  -webkit-filter: grayscale(1); !* Webkit Nightlies & Chrome Canary *!*/


/*  -webkit-transition: all 0.5s ease-in-out;*/


/*}*/

.community-profile .statistic {
    border: 1px solid #3eccb8 !important;
    padding: 10px !important;
    min-width: 108px !important;
    border-radius: 100px !important;
}

.km-button--primary {
    background-color: #5c5aa7;
    color: #000000;
}

.km-button {
    border-radius: 3px;
    -webkit-appearance: none;
    border: none;
    outline: none;
    background: white;
    height: 36px;
    padding: 0px 16px;
    margin: 0;
    font-size: 14px !important;
    font-weight: 400 !important;
    text-align: center;
    min-width: 70px;
    transition: all 0.3s ease-out;
}

.km-btn-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.km-btn-file-label {
    line-height: 36px;
    cursor: pointer;
}

.sidebarButton {
    position: absolute;
    right: 0px;
    top: 160px;
    z-index: 10;
}


/* - START - Announcement Related CSS Properties______________________________________________________________________*/

.main-container-new-announcements {
    /* Instant notification on the top of all pages */
    margin-top: 45px !important;
    text-align: center !important;
    background-color: rgb(255, 255, 255) !important;
    border-bottom: 0.5px solid #1c1c4d;
    margin-bottom: -70px !important;
}

#new-announcement-warning-msg {
    /* This is the "new announcement.1 | " word writing on the top of page when new ann comes */
    color: rgb(223, 55, 55);
    letter-spacing: 1px;
}

.new-announcement-change-button-arrows {
    color: gray !important;
    margin-right: 20px !important;
}

#new-announcement-change-button-checkmark {
    color: rgb(0, 194, 0);
}

#new-announcement-change-button-checkmark:hover {
    color: rgb(32, 226, 32);
    cursor: pointer;
}

.new-announcement-change-button-arrows:hover {
    color: rgb(56, 56, 56) !important;
    cursor: pointer;
}

#announcement-list-modal {
    width: 85%;
}

.announcement-list-panes {
    overflow: auto;
    border: none !important;
    padding: 0.4em 0.4em !important;
    max-height: 600px;
}

.announcement-form-left-right-parts {
    width: 48%;
}

.announcement-max-word-limit {
    display: flex;
    justify-content: space-between;
}

#div-announcement-form-date-pickers {
    display: flex;
    justify-content: space-between;
}

.each-date-picker-announcement-form {
    width: 49%;
}

.form-inputs-required-sign {
    color: red;
}

.ann-form-manual-segment {
    text-align: center;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 1px 2px 0 rgb(34 36 38 / 15%);
    padding: 0.8em 0.8em;
    margin-bottom: 0.9rem;
    border-radius: 0.28571429rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
}

.ann-target-segment-customized {
    padding: 0.95em 0.8em !important;
}

.text-of-show-more {
    text-decoration: underline;
    color: black;
    font-weight: bold;
}

.text-area-preserve-writing-format {
    /* Display proper text,for preserving the format you write in <textarea> */
    white-space: pre-wrap;
}

.text-of-show-more:hover {
    cursor: pointer !important;
}

#showMoreAnnouncementTargetModal {
    width: 300px;
    padding: 10px;
}

.show-more-resuable-dialogbox-p {
    padding-left: 20px;
    padding-right: 20px;
}

.show-more-dialogbox-segment-announcement-targets {
    background-color: rgb(240, 240, 240) !important;
    position: absolute;
    max-height: 500px !important;
    width: 400px;
    top: 30%;
    left: 50%;
    z-index: 99999999;
}

.show-more-dialogbox-inner-div-announcement-targets {
    overflow-y: auto;
    max-height: 250px;
}

#ann-form-checkbox-options {
    padding-top: 1rem !important;
    display: flex;
    justify-content: space-between;
}

#ann-form-target-group-checkboxes-div {
    width: 66%;
}

#ann-form-status-checkbox-div {
    width: 32%;
}

.overflow-hidden {
    /* Reusable mutual class */
    overflow: hidden;
}

.dsp-flx-jstfy-contnt-space-btw {
    /* Reusable mutual class */
    display: flex;
    justify-content: space-between;
}

.dsp-flx-jstfy-contnt-space-evenly {
    display: flex;
    justify-content: space-evenly;
}

#new-announcement-activation-slider:checked~.box:before {
    background-color: rgb(2, 212, 51) !important;
}

#new-announcement-activation-slider:checked~label:before {
    background-color: rgb(2, 212, 51) !important;
}

#new-announcement-target-group-dropdown-outer {
    width: 100%;
}

.announcements-tab {
    width: 100%;
    margin: 0px auto;
    text-align: center;
    padding: 10px;
}

#main-container-new-announcements-table {
    margin-top: 67.5px;
    width: 102%;
    background-image: url(../src/assets/images/new-announcement-background-flasher.gif);
    background-size: contain;
    left: -2px;
    position: relative;
}

.each-publishing-annoucement-segment {
    display: flex;
    justify-content: space-between;
}

.group-labels {
    margin-left: 3px !important;
    margin-bottom: 5px !important;
}

.left-part-publishing-ann-segment {
    text-align: center;
    color: black;
    padding: 10px;
    width: 10%;
}

.ann-reveal-function-divs{
    background-color: white;
}

.ann-reveal-function-divs:hover{
    cursor: pointer;
}

.new-word-ann-modal{
    color: green;
    font-size: 18px;
    letter-spacing: 2px;
}


.vertical-line-as-hr{
    height: 80%;
    border: 1px solid rgb(216, 216, 216);
    margin-right: 20px;
    margin-left: 10px;
}

.vertical-line-div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%
}

.unread-ann-div-as-button{
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.announcer-icon-and-name-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.mark-as-unread-ann-icon:hover{
    color:rgb(209, 0, 0);
    cursor: pointer;
}

.right-part-publishing-ann-segment {
    width: 89%;
}

.text-align-c-imp {
    text-align: center !important;
}

.table-border-none {
    border: none !important;
}

#responsive-announcements {
    display: none;
}

#responsive-instant-new-announcement {
    color: white;
    background: grey;
    border-radius: 5px;
    padding: 4px;
    margin-left: 4px;
}

@media (max-width: 768px) {
    #set-icon-visibility-responsive {
        display: none;
    }
    #non-responsive-announcements {
        display: none;
    }
    #responsive-announcements {
        display: block;
    }
    .navbar-menu-item {
        border-bottom: 0px !important;
        border-left: 0px !important;
    }
}

#app-upload-modal-announce-and-resign-app{
    display: flex;
    justify-content: space-between;
}

.manuel-segment-in-app-upload-modal{
    text-align: left;
    margin-top:20px;
    margin-right: 10px;
    width: 48%;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 1px 2px 0 rgb(34 36 38 / 15%);
    padding: 0.8em 0.8em;
    border-radius: 0.28571429rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
}

.manuel-segment-in-app-upload-modal-pop-up{
    margin-left: 2px;
}


/* - END - Announcement Related CSS Properties______________________________________________________________________*/

.react-datepicker-wrapper {
    width: 100% !important;
    color: rgb(0, 71, 12)
}

.ReactTable .rt-thead .rt-th {
    line-height: 3 !important;
    /*margin: 0px 10px 0px 10px;*/
    /*it was a problem when resizing header*/
    font-size: 14px !important;
    font-weight: 500 !important;
    display: grid !important;
    margin: 8px 0 8px !important;
}

.ReactTable .rt-tr-group {
    line-height: 2.5;
    /*margin: 10px 10px 0px 10px;*/
    /*it was a problem when resizing header*/
}

.rbc-calendar {
    min-height: calc(80vh - 90px);
}

.load {
    background: rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.load-device {
    background: rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 90vh;
}

#loaderText {
    color: #fff;
    font-family: monospace;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: 0.1em;
    animation: typing 2.25s steps(30, end) infinite;
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}


/* RE-BRANDING AND FACTORING UGUNCAN 2020 FEB */


/* START:SIDE MENU STYLES */

.side-bar-u {
    padding-top: 0px;
    background-color: #0548a6;
    height: 100%;
    position: fixed;
    width: 60px;
    top: 0px;
    z-index: 99999;
}

.sb-logo {
    padding-top: 9px;
    padding-bottom: 9px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.18) !important;
}

.sb-menu-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sb-menu-item {
    margin: 10px;
    cursor: pointer;
    color: #ffffff;
}

.sb-menu-item :hover {
    color: #ffab00;
    transition: 0.3s;
}

.sb-menu-item-active {
    color: #ffab00;
}

.to-bottom {
    position: absolute;
    bottom: 0;
    left: 0px;
    background-color: #0545a5;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 60px;
}


/* END:SIDE MENU STYLES */


/* START:PAGE HEADER */

.page-header {
    background-color: white;
    padding: 20px 0px 20px 20px !important;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
}

.page-title {
    display: flex;
}

.page-icon {
    background-color: #FF5630;
    display: flex;
    margin-right: 10px;
    width: 40px;
    border-radius: 4px;
    padding-top: 7px;
}

.page-icon i {
    color: white;
    font-size: 18px !important;
    margin: 0px auto !important;
}

.page-header>.column {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
}

.page-actions {
    align-items: center;
    display: flex;
}

.header-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.device-list-header>div>h3>i {
    font-size: 34px !important;
    color: #344563;
}

.device-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}


/* END:PAGE HEADER */


/* START:DEVICE CARD */

.device-filter {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
}

.device-card {
    display: flex;
    flex-direction: row;
}

.device-card-image {
    width: 4.8rem;
    max-width: none !important;
}

.device-card-content {
    padding: 4px 10px 10px 20px;
    display: flex;
    flex-direction: column;
    font-size: 12px !important;
    color: #474949;
    /* text overflow problem*/
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    word-break: break-all;
    min-height: 135px;
    min-width: 130px;
    flex-grow: 1;
}


/*.device-card-content:hover{*/


/*  text-overflow: clip;*/


/*  white-space: normal;*/


/*  word-break: break-all;*/


/*}*/

.device-card-name {
    color: #5E6C84;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.device-card-name a {
    color: #5E6C84;
}

.device-card-name a:hover {
    color: #FF5630 !important;
}

.no-access {
    color: #dedede !important;
    pointer-events: none !important;
}

.device-icon {
    padding-top: 10px !important;
    vertical-align: center !important;
}

.device-icon i {
    color: #5E6C84;
    font-size: 16px !important;
    cursor: pointer;
}

.device-icon i:hover {
    color: #0545a5;
    font-size: 16px !important;
}


/* END:DEVICE CARD */


/*START DEVICE USAGE*/

.react-datepicker-popper {
    z-index: 999999 !important;
}

.react-datepicker {
    width: max-content !important;
}


/*START DEVICE USAGE*/


/*START DEPLOYMENT APP LIST*/

.textOverflow {
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}


/*END DEPLOYMENT APP LIST*/


/*START EDIT DEVICE FIELDS*/


/*END EDIT DEVICE FIELDS*/


/* OVERWRITE */

.ui.primary.button {
    background-color: #0052cc;
}

.ui.primary.button :hover {
    background-color: transparent;
}

.device-actions {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    margin-top: 8px;
}

.device-actions .icon {
    margin-bottom: 5px !important;
    width: auto;
}

.ui.form .field.disabled>label,
.ui.form .fields.disabled>label {
    opacity: 1 !important;
}

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
    opacity: 1 !important;
}

.ui.form .inline.fields .field>.selection.dropdown,
.ui.form .inline.field>.selection.dropdown {
    width: 100% !important;
}


/* START: INSPECTOR */

#inspector-page-parent-segment{
    display: flex;
    flex-direction: column;
}

#inspector-container-grid{
    position: fixed;
    margin-left:0.5%;
}

#landscape-inspector-page-outmost-container{
    height: calc(60vh - 120px);
    overflow-y: scroll;
}

#portrait-inspector-page-outmost-container{
    height: calc(87vh - 120px);
    overflow-y: scroll;
}

#landscape-inspector-page-inner-container{
    width: 99%;
}

#portrait-inspector-page-inner-container{
    display:flex;
    justify-content: space-around;
    width: 99%;
}

#portrait-div-for-frozen-inspector-screen[name='display-hide'] {
    display: none;
}

#portrait-div-for-frozen-inspector-screen[name='display-show'] {
    width: 36%;
}

#portrait-div-for-frozen-inspector-screen {
    display: flex;
    justify-content: center;
}

#landscape-div-for-frozen-inspector{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.rc-tree li .rc-tree-node-content-wrapper {
    height: 24px !important;
}

.rc-tree-node-selected {
    background-color: #bae7ff !important;
    border: 0 !important
}

.rc-tree-node-content-wrapper {
    word-break: break-word !important;
    white-space: initial !important;
    display: initial !important;
}

.elementBox {
    position: absolute;
    display: flex;
    visibility: visible;
}

.hoveredElementBox {
    background-color: #ffab00;
    opacity: 0.3;
    position: absolute;
    display: flex;
    visibility: visible;
}

.selectedElementBox {
    background-color: blue;
    opacity: 0.4;
    position: absolute;
    display: flex;
    visibility: visible;
}

.specialElementBox {
    background-color: mediumturquoise;
    opacity: 0.3;
    position: absolute;
    display: flex;
    visibility: visible;
}

.recorded-code {
    font-family: monospace;
    white-space: pre-wrap;
    word-break: break-all;
    margin-bottom: 20px;
}

.swipeInstructions {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100000000;
    background-color: white;
    padding: 4px;
    width: 10em;
}

.swipeSvg {
    position: absolute;
    z-index: 100000000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.swipeSvg line {
    stroke-width: 15;
    stroke: rgba(255, 153, 153, 0.8);
    stroke-linecap: round;
}

.swipeSvg circle {
    r: 10;
    fill: rgba(255, 153, 153, 0.8);
}

.searchPanel {
    position: fixed;
    top: 30%;
    left: 60%;
}

.searchSegment {
    position: absolute;
    width: 400px;
    top: 30%;
    left: 10%;
    z-index: 99999999;
}

.searchSegment2 {
    position: absolute;
    width: 400px;
    top: 30%;
    left: 10%;
    z-index: 99999999;
}

.searchDimmer {
    background-color: rgba(0, 0, 0, 0.05) !important;
}


/* END: INSPECTOR */

.settings-container {
    margin: 0px !important
}

.settings-container .page-header {
    margin: 0px 0px!important;
    padding-left: 0px !important;
}

.react-pdf__Document {
    display: flex !important;
    justify-content: center !important;
}

.os-icon {
    color: #93949d !important;
    pointer-events: none !important;
    vertical-align: middle !important;
    padding: 1px 1px 1px 5px !important;
}

.verticalLine {
    border-left: #cacef3 1px groove !important;
}

.ImagePreview {
    display: flex !important;
    margin: auto !important;
}

.centeredPlaceholder .ui input {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace !important;
    width: 30vw !important;
}

.toast_custom {
    word-break: break-all !important;
}

.device-card-container {
    border-radius: 10px !important;
    background: #ffffff !important;
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff !important;
}

.device-card-container:hover {
    /* box-shadow:  10px 0px 10px #e9e9e9,
  -20px -20px 60px #ffffff !important; */
    background: #f0f0f0 !important;
}

.app-card-container {
    border-radius: 10px !important;
    background: #ffffff !important;
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff !important;
}

.manage-group-container {
    height: 65vh;
    overflow-y: auto;
    margin-top: 7px;
}

.file-cell-with-image {
    display: flex;
    justify-content: flex-start;
}

.app-card-container:hover {
    box-shadow: 10px 0px 10px #d9d9d9, -20px -20px 60px #ffffff !important;
}

.filter-accordion {
    border-top: 10px !important;
    border-radius: 10px !important;
    background: #ffffff !important;
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff !important;
    overflow-y: auto;
    height: 60vh;
    max-width: 340px !important;
    width: 340px !important;
}

.usage-status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    padding-top: 12px;
    border-top: 1px solid #dedede;
}

.usage-status-container>h4 {
    font-size: 14px;
}

div[id^="remoteSegment-"] {
    -webkit-box-shadow: -5px 36px 41px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -5px 36px 41px 0px rgba(0, 0, 0, 0.75);
    box-shadow: -5px 36px 41px 0px rgba(0, 0, 0, 0.75);
}

.device-manage-right {
    padding-top: 20px;
    background-color: #f0f1f5 !important;
}

.captcha-field {
    display: flex;
    justify-content: center;
}
#netas-logo {
    height: 20px; 
}

#oauth2-login-segment{
    display: flex;
    flex-direction: column;
}

#oauth2-login-navigator {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

#sign-in-with-label {
    color: #172B4D;
}

.oauth2-logo{
    object-fit: contain;
    height: 32px;
    width: 32px;
    margin-right: 10px;
}

.otp-modal-header {
    text-align: center;
}

.otp-modal-description {
    text-align: center;
    color: green;
}

.ui.tabular.fluid.menu {
    overflow-y: hidden;
    overflow-x: auto;
    width: calc(100% + (1px * 2)) !important;
}

.ui.pointing.menu {
    overflow-y: hidden;
    overflow-x: auto;
}

.attentionButton {
    background: repeating-linear-gradient(135deg, #DB2828, #737373 100px) !important;
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@media (max-width: 1500px) {
    .multi-device-column {
        width: 50% !important;
    }
}

@media (max-width: 1024px) {
    .multi-device-column {
        width: 100% !important;
    }
}

@media (min-width: 768px) {
    .set-text-visibility-responsive {
        display: none;
    }
}

.landscape-mode {
    width: 50% !important;
}

.device-card-category-container {
    display: flex;
    flex-wrap: wrap;
    max-height: 45px;
    overflow-y: hidden;
}

.device-card-category-container:hover {
    overflow-y: auto;
}

.device-card-category-item {
    border: 1px solid #474949;
    border-radius: 5px;
    padding: 0 5 1px 1px;
    margin: 1px;
}
.edit-device-input-container {
    display: flex !important;
    margin-bottom: 5px !important;
}

.edit-device-input {
    flex: 1 !important;
    min-width: 0;
    margin-right: 10px !important;
}

#edit-device-image-remove-button {
    margin-left: auto;
    margin-right: 5px;
}

.edit-device-display-flex-column {
    display: flex !important;
    flex-direction: column !important;
}

#edit-device-category-delete-button {
    min-width: 0 !important;
}

.edit-device-reservable {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

#edit-device-modal-size {
    width: 100% !important;
}

#edit-device-modal-content-size {
    width: 100% !important;
    display: flex !important;
}
.rt-resizable-header-content {
    white-space: pre;
    line-height: 1 !important;
}

#collect-system-data-button {
    background-color: #009c95 !important;
    color: white !important;
    text-align: center !important;
}

.device-card-content-sidebar {
    padding: 4px 10px 10px 20px;
    display: flex;
    flex-direction: column;
    font-size: 12px !important;
    color: #474949;
    /* text overflow problem*/
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    word-break: break-all;
    min-height: 135px;
    min-width: 130px;
    flex-grow: 1;
    text-align: left !important;
}

.card-group-sidebar {
    margin-top: 10px !important;
    margin-right: 0 !important;
}

.device-card-last-used-container {
    display: flex !important;
    flex-direction: column !important;
    margin-top: 3px !important;
}

.device-card-container-sidebar {
    background: #ffffff !important;
    display: flex !important;
    flex-grow: 1 !important;
    border: none !important;
    box-shadow: none !important;
    border-bottom: 1px solid #dfdfdf !important;
    border-radius: 0px !important;
    margin: 0px 0px 0px 10px !important;
}

.device-card-container-sidebar:hover {
    background: #f0f0f0 !important;
}

.device-sidebar-component {
    background-color: #ffffff !important;
    -webkit-box-shadow: -15px 0px 26px 1px rgba(84, 84, 84, 0.56) !important;
    box-shadow: -15px 0px 26px 1px rgba(84, 84, 84, 0.56) !important;
}

.sidebar-tab-component .menu .item {
    padding-top: 37px !important;
    text-align: center !important;
    left: 35px !important;
    bottom: 20px !important;
}

.error-react-toastify {
    white-space: pre-line !important;
    word-break: break-all !important;
}

.languageDropdownHover:hover {
    background: rgb(236, 236, 236);
}

.change-device-locale {
    width: min-content !important;
}

.change-device-locale input {
    width: calc(40vh) !important;
}

.change-device-locale button {
    width: calc(10vh) !important;
}

.license-signature span {
    word-break: break-all;
    white-space: normal;
}

.flagAlignmentResponsive div {
    width: 100%;
    height: 100%;
    text-align: left;
}

#system-params-min-max-label{
    margin-top: 3px;
    font-style: italic;
    font-weight: normal;
}

.top-menu-visium-farm-logo {
    width: 130px;
    margin-top:0.45rem;
    max-height: 2.32rem !important;
    height: 2.32rem;
    background-image: url(../src/assets/images/visium-farm-logo.png);
    background-size: 100%;
    background-repeat: no-repeat;
}
.top-menu-visium-farm-logo:hover{
    cursor: pointer;
}

#language-flags-container-div{
    display: flex;
    justify-content: space-evenly;
}

.language-flag-outer-div{
    padding: 2.5px;
    border-radius: 30px;
    background: rgb(239, 239, 239);
    padding-right: 8px;
    opacity: 0.5;
}

.language-flag-outer-div:hover{
    opacity: 1.0;
    background: rgb(199, 210, 254);
}

.language-flag-outer-div-selected{
    padding: 2.5px;
    border-radius: 30px;
    background: rgb(199, 210, 254);
    padding-right: 8px;
}

#lang-flag-seperator-div-as-hr{
    border-left:0.2px solid rgb(215, 215, 215);
    height: 54%;
    position: absolute;
}

.language-flag{
    width: 20px !important;
    height: 20px !important;
}

#version-promoter-modal{
    background-color: rgb(241, 241, 255);
 }

.version-promoter-modal-header{
    text-align: center;
    color: white !important;
    background: #1c1c4d !important;
}

.version-promoter-modal-content{
    height: 500px;
    background: rgb(249, 249, 255) !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 10px !important;
}

.version-promoter-empty-modal-content{
    width: 100%;
    text-align: center;
}

.version-promoter-tab{
    width: 100%;
}

.category-dropdown{
    width: 100%;
    margin-bottom: 10px;
}

#edit-device-category-delete-button {
    min-width: 0 !important;
    width: 100%;
}

.version-promoter-modal-pane-each-tab{
    padding-right: 20px !important;
}

.version-promoter-modal-segment{
    width: 100%;
    min-height: 100px;
}

.version-promoter-modal-segment:hover{
    background-color: rgb(248, 248, 248) !important;    
}

.version-promoter-modal-type-label{
    background-color:#1c1c4d !important;
    color: white !important;
}

.version-promoter-modal-type-label-text{
    margin-left:-6px;
    margin-right:6px;

}

.version-promoter-modal-segment-header{
    letter-spacing: 0.5px;
    position: absolute;
    left: 16.5px;
    top:18px;
}

.version-promoter-modal-segment-description{
    padding: 3px;
    margin-top: 8px;
}

.version-promoter-modal-actions{
    text-align: right !important;
}
.version-promoter-modal-ok-button{
    background: #1c1c4d !important;
    color:white !important;
    margin-right: 7px !important;
}

.text-white{
    color: white !important;
}



.custom-dropdown {
    display: flex;
    align-items: center;
}

.custom-dropdown .dropdown {
    flex-grow: 1;
}

.custom-dropdown .icon {
    margin-right: 5px;
}

.menuItem:hover{
    background-color: #2E2E5B !important;

}
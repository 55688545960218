body {
    height: 100% !important;
}

#root {
    height: 100% !important;
}

.nav-bar-new {
    background-color: #1c1c4d !important;
}

.vf-logo-on-nav-bar{
    margin-left:40px;
    width: 130px;
    padding-top: 6px;
}

.main-container {
    padding-top: 48px !important;
    display: flex !important;
    min-height: 100vh !important;
    height: 100% !important;
}

.main-container-report {
    margin-top: 50px !important;
    text-align: center !important;
    background-color: rgb(255, 255, 255) !important;
    padding: 60px 60px 60px 50px;
}

.main-container-settings {
    margin-top: 61px !important;
    min-height: 100% !important;
    height: 100% !important;
}

.main-right-container-settings {
    display: flex !important;
    flex-grow: 1 !important;
    background-color: rgb(255, 255, 255);
    padding: 60px 30px 10px 30px;
    flex-direction: column !important;
    margin-right: -14px !important;
    height: 100% !important;
}

.reporting-right-container {
    display: flex !important;
    flex-grow: 1 !important;
    background-color: rgb(255, 255, 255);
    padding: 60px 30px 0px 30px;
    flex-direction: column !important;
    margin-top: -104px !important;
    margin-right: -50px !important;
}

.main-left-container {
    display: flex !important;
    min-width: 400px !important;
    background-color: rgb(238, 238, 238);
    flex-direction: column !important;
    padding: 45px 50px 30px 60px;
}

@media (max-width: 824px) {
    .main-left-container-fixed {
        position: static !important;
    }
}

.main-left-container-fixed {
    position: fixed;
}

.main-left-container h1 {
    color: #090d46 !important;
    font-weight: 400 !important;
}

.main-left-content {
    margin-top: 20px;
}

.main-right-container {
    display: flex !important;
    flex-grow: 1 !important;
    background-color: rgb(255, 255, 255);
    padding: 45px 30px 10px 50px;
    flex-direction: column !important;
}

.main-right-header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding-right: 8px !important;
    padding-bottom: 20px;
}

.main-right-buttons {
    justify-content: flex-end !important;
}

.main-right-header h2 {
    margin: 0px !important;
    font-weight: 300 !important;
}

.device-cards-container>.ui {
    margin: 0px !important;
    display: flex !important;
    justify-content: center !important;
}

#logs-segment{
    height: 100% !important;
}

#logs-buttons-div{
    padding-left: 5px;
    padding-top: 5px;
    position: absolute;
}

.device-card-id-span {
    word-break: break-word !important;
    white-space: pre-line !important;
    cursor: pointer !important;
}

.device-card-status-span {
    display: flex;
    word-break: break-word !important;
    white-space: pre-line !important;
}

.device-card-checkbox {
    bottom: -3px !important;
}

.count-total-number {
    font-weight: bold;
    margin-top: 40px;
    color: #636363 !important;
}

#count-total-number-of-user {
    font-weight: bold;
    font-size: 16px;
    color: #636363 !important;
}

.filterbar-checkbox {
    margin-right: 1.2rem;
}

.filterbar-radio {
    margin-left: 1rem;
}


/* Filter Bar attribute */

.filter-item-client-scroll-bar {
    max-height: 299px;
    overflow-y: auto;
}


/* OVERWRITES */

.main-container-settings .vertical.secondary.menu {
    padding: 80px 40px 10px 50px !important;
}

.filter-accordion {
    background: none !important;
    box-shadow: none !important;
    border: none !important;
    padding-top: 10px;
}

.filter-accordion .item .title {
    border: none !important;
    font-weight: 300 !important;
    color: #000 !important;
}

.filter-accordion .item .content {
    padding-left: 30px !important;
}

.device-card-container {
    box-shadow: none !important;
    border: 1px solid #e3e6f0 !important;
    height: 180px !important;
}

.device-card-content-sidebar {
    padding: none !important;
}

div[id^="remoteSegment-"] {
    border: 12px solid #ffffff !important;
    border-radius: 8px;
    box-shadow: 1px -1px 5px 0px rgba(181, 181, 181, 0.75);
    -webkit-box-shadow: 1px -1px 5px 0px rgba(181, 181, 181, 0.75);
    -moz-box-shadow: 1px -1px 5px 0px rgba(181, 181, 181, 0.75);
}

.deviceImage {
    border-radius: 8px;
    max-width: 100%;
    max-height: 100%;
}

.segment-shadow {
    box-shadow: none !important;
    border-radius: 2px !important;
}

.column .device-manage-right {
    background-color: white !important;
    margin-top: -10px !important;
}

.ui.form .field>label {
    font-weight: 300 !important;
}

.page-container-full {
    min-height: 100% !important;
}


/* UTILITY CLASSES */

.flex {
    display: flex !important;
}

.text-align-center{
    text-align: center;
}

.justify-between {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.bg-gray-50 {
    background-color: #fbfbfb !important;
}

.center {
    display: flex !important;
    justify-content: center !important;
}

.main-container-report .ui.secondary.menu {
    display: flex !important;
    justify-content: flex-end !important;
    margin-top: -45px !important
}

.main-container-report .ui.form {
    justify-content: flex-end !important
}

.automation-settings-tab-content {
    margin: 20px 10px 20px 10px;
    min-height: calc(50vh - 100px);
}

.device-manage-page-actions {
    /* margin-top :100px !important; */
    position: fixed !important;
    bottom: 40px;
    z-index: 99 !important;
    left: 20px !important;
}

.device-manage-page-actions>div {
    display: flex !important;
    flex-direction: column !important;
}

.device-manage-automation-row-padding-top {
    padding-top: 0 !important;
}

.device-manage-automation-code-segment {
    overflow: auto !important;
    max-height: 330px !important;
}

.device-manage-automation-select-app-label {
    white-space: nowrap !important;
}

.main-container-api-doc {
    margin-top: 100px !important;
}

.device-card-dropdown>i {
    vertical-align: middle !important;
}

.device-reservation-info-label {
    margin-right: 5px !important;
}

.device-reservation-info-segment {
    line-height: 2.2 !important;
    margin: 3px 0 10px 0 !important;
    padding: 0 !important;
}

.device-reservation-info-label-now {
    margin-right: 5px !important;
    animation: blinker 2.2s linear infinite !important;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.device-instance-time-info {
    text-align: center;
    color: red;
}

.timeout-toast {
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: red !important;
    text-align: center;
}

.manage-inspector-find-by-table {
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre-line;
    margin-top: 50px
}

.manage-inspector-attribute-table-div {
    display: inline-block;
    overflow-y: scroll;
    max-height: 400px;
    width: 100%;
}

.manage-inspector-attribute-table-head-cell {
    position: sticky;
    top: 0;
    background: white !important;
}

.test-session-info-modal-td,
#deployment-table-container .rt-resizable-header-content,
#settings-other-info-table td {
    white-space: normal !important;
}

.system-parameters-table .rt-thead .rt-th:nth-of-type(1),
.system-parameters-table .rt-tbody .rt-td:nth-of-type(1) {
    max-width: 275px !important;
    min-width: 100px !important;
}

.system-parameters-table .rt-thead .rt-th:nth-of-type(2),
.system-parameters-table .rt-tbody .rt-td:nth-of-type(2) {
    max-width: 145px !important;
    min-width: 100px !important;
}

.main-container-settings>.ui.grid>.four.wide.column {
    min-width: 400px !important;
}

.main-container-settings>.ui.grid>.twelve.wide.column {
    flex: 1 !important;
    min-width: 0 !important;
}

#user-settings-actions {
    display: none;
}

.other-opts-button {
    min-width: 190px !important;
}

.password-rule-input-box input {
    width: 75% !important;
}

.add-user-input-field {
    flex: 1 !important;
}

@media screen and (min-width: 768px) {
    #navbar-menu-toggle-icon {
        display: none;
    }
}

@media screen and (max-width: 1440px) and (min-width: 824px) {
    #device-list-container {
        max-width: calc(100% - 400px) !important;
    }
}

@media screen and (max-width: 824px) {
    .main-container,
    .main-container-settings>.ui.grid {
        flex-direction: column !important;
    }
    .main-container-settings>.ui.grid>.four.wide.column,
    .main-container-settings>.ui.grid>.twelve.wide.column {
        width: 100% !important;
    }
}

@media screen and (max-width: 926px) and (min-width: 824px) {
    .profile-page-left-menu {
        min-width: 320px !important;
    }
}

@media screen and (max-width: 1320px) and (min-width: 824px) {
    #test-sessions-container {
        max-width: calc(100% - 400px) !important;
    }
}

@media screen and (max-width: 1620px) and (min-width: 824px) {
    #deployment-table-container {
        max-width: calc(100% - 400px) !important;
    }
}

@media screen and (max-width: 1080px) {
    .user-settings-button-group {
        display: none;
    }
    #user-settings-actions {
        display: inline-block;
    }
}

@media screen and (max-width: 1280px) {
    #other-opts-button-group {
        flex-direction: column !important;
    }
    .other-opts-button {
        width: 100% !important;
    }
}

.shared-url-row {
    align-items: flex-end !important;
}

.shared-url-copy-button {
    margin-right: 10px !important;
}

.shared-url-expired-button {
    margin-left: 5px !important;
}

.shared-url-link-type-radio {
    display: 'flex';
    flex-direction: 'column';
    justify-content: 'center';
}

.z-index-999 {
    z-index: 999;
}

/* Gallery View Device List Card Custom Half-Android Icons' Attributes ---------------------------*/

.icon-customizedFor-googleService {
    background: url('../src/assets/images/iconGoogleServNotActive.png');
    height: 15px;
    background-size: 100%;
    width: 15px;
    display: inline-block;
    margin-bottom: -30.0%;
    margin-left: 14%;
}


/* Table View Device List Card Custom Half-Android Icons' Attributes ---------------------------*/

.icon-customizedFor-googleService_Table {
    background: url('../src/assets/images/iconGoogleServNotActive.png');
    height: 13.5px;
    background-size: 100%;
    width: 13.5px;
    display: inline-block;
    margin-bottom: -12.0%;
    margin-left: 14%;
}


/* Galeri View Device List Card Icon Attributes (except for Custom Half-Android Icon) ----------------------*/

.icon-deviceLists {
    margin-top: 5.7% !important;
    position: absolute;
    margin-left: -7.4% !important;
}


.licenseInfoTable-leftColumn {
    width: 20%
}

.license-table td {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ui.dropdown>.text {
    display: inline-block;
    transition: none;
    overflow-x: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ui.selection.selectAppDropdown .menu>.item {
    border-top: 1px solid #fafafa;
    padding: .78571429rem 1.14285714rem!important;
    display: flex;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
}


/*BELOW CODE SEGMENT : React Table Operations--------------------------------- */

#loading-or-no-data-react-table {
    text-align: center;
    margin-top: 20px;
}

.react-table-wrapper {
    margin-bottom: 10px;
    border-width: 1px;
    padding: 10px;
    overflow: hidden;
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
    border-radius: 0.25rem;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.react-table-outer {
    position: relative;
    margin-top: 20px;
    min-height: 550px;
    overflow: auto;
    overflow-x: auto;
    border-radius: 4px;
    border: 1px solid lightgray;
}

.react-table {
    position: absolute;
    min-width: 100% !important;
    overflow-x: hidden;
}

.react-table-header {
    color: #4B5563;
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity));
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.react-table-header-row {
    /* Dedicated for Rt Header Row and will be used when required */
}

.react-table-header-cell {
    position: relative;
    padding: 0.25rem;
    height: 49px;
    white-space: nowrap;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    text-align: left;
    letter-spacing: 0.025em;
    border-right-width: 1px;
}

.react-table-search-row {
    /* Empty for future increments needed*/
}

.react-table-search-cell {
    padding: 2px;
    text-align: left;
}

.react-table-body {
    color: #1c1c4d;
}

.react-table-body-row {
    /* Empty for future increments needed*/
}

.react-table-body-cell {
    /* white-space: normal; */
    word-break: break-all;
    padding: 0.55rem;
    text-align: left;
}

.odd\:bg-white:nth-child(odd) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.even\:bg-slate-50:nth-child(even) {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.space-x-2> :not([hidden])~ :not([hidden]) {
    display: flex;
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.debounce {
    padding: 0.6rem;
    border-radius: 0.375rem;
    border: 1px solid rgb(209 213 219);
    font-size: 1.125rem;
}

.debounce-input-number {
    width: 6rem;
    border-width: 1px;
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-radius: 0.25rem;
}

.filter-table-column-debounce-input-text {
    width: 100%;
    height: 100% !important;
    margin-top: 1.8px !important;
}

.table-flex-gap {
    display: flex;
    gap: 0.5rem;
}

.my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.my-1-5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
}

* {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    font-size: 14px;
}

.resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background: rgba(0, 0, 0, 0.5);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
}

.resizer.isResizing {
    background: blue;
    opacity: 1;
}

@media (hover: hover) {
    .resizer {
        opacity: 0;
    }
    *:hover>.resizer {
        opacity: 1;
    }
}


/*BELOW CODE SEGMENT:  React Table Pagination Settings-------------- */

.table-pagination-outframe {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
}


/* Left side of pagination part */

.table-pagination-left-part {
    display: flex;
    justify-content: left;
    gap: 0.5rem;
}

#pagination-bounce-dropdown-selection {
    cursor: pointer;
    height: 35px;
    padding: 0.5em;
    border-radius: 4px;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219/var(--tw-border-opacity));
}

#table-pagination-go-to-page-input {
    cursor: pointer;
    text-align: right;
    width: 5rem;
    /* height: 34px; */
    padding: 0.3em;
    border-radius: 3px;
    border: 0.1px solid rgb(209, 209, 209);
}


/* Right side of pagination part */

.pagination-change-buttons-table {
    /* Prev and Next buttons have been covered within a table. */
    border-collapse: collapse;
    border: 0.1px solid rgb(255, 255, 255);
}

.table-pagination-button {
    width: 50px;
    height: 36px;
    border: none;
    border-radius: 2.5px;
    color: #5c5c5c;
    background-color: #cdcdcd;
    cursor: pointer;
}

.table-pagination-button:hover {
    color: #e5e5e5;
    background-color: #5c5c5c;
}

.table-pagination-button:disabled {
    color: #bababa;
    border: none;
    background-color: #f1f1f1;
    cursor: default;
}

.table-pagination-page {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    text-align: right;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
}

#filter-columns-dropdown {
    width: 100%;
    height: 30px !important;
}


/* (END OF)ABOVE CODE SEGMENT React Table Operations Above---------------------------- */

.file-card-container {
    border-radius: 10px !important;
    background: #ffffff !important;
    box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff !important;
}

.file-card-container:hover {
    background: #f0f0f0 !important;
}

.file-card {
    display: flex;
    flex-direction: row;
}

.file-card-content {
    padding: 4px 10px 10px 20px;
    display: flex;
    flex-direction: column;
    font-size: 12px !important;
    color: #474949;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    min-height: 135px;
    min-width: 130px;
    flex-grow: 1;
}

.file-card-name {
    color: #5E6C84;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.file-card-name a {
    color: #5E6C84;
}

.file-card-name a:hover {
    color: #FF5630 !important;
}

.file-deploy-main-container {
    display: flex;
    min-height: 100vh;
    width: 100%;
}

.file-deploy-left-container {
    display: flex !important;
    min-width: 300px !important;
    background-color: rgb(238, 238, 238);
    flex-direction: column !important;
    padding: 45px 50px 30px 60px;
}

.file-deploy-left-content {
    margin-top: 20px;
}

.file-deploy-left-container h1 {
    color: #090d46 !important;
    font-weight: 400 !important;
}

.file-deploy-right-container {
    display: flex;
    flex-grow: 1;
    background-color: rgb(255, 255, 255);
    padding: 45px 30px 10px 50px;
    flex-direction: column;
}

.file-deploy-right-header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding-left: 8px;
    padding-bottom: 20px;
}

.file-deploy-right-header h2 {
    padding: 0px !important;
    font-weight: 300 !important;
}

.flex-justify-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.alarms-filter {
    margin-top: 2.5rem;
}

#alarms-edit-button {
    float: left;
    width: min-content;
    margin: auto;
}

.custom-parameters {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
}

.alarms-filter {
    margin-top: 2.5rem;
}

.integration-param-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    justify-content: center;
}

.integration-param-span {
    margin: auto;
    margin-right: 1.5rem;
}

#integration-param-span-remove-button {
    margin: auto;
    margin-right: 1.2rem;
    text-align: center;
    height: 32px;
    width: 32px
}

.clipboard-div {
    position: absolute;
    width: 100%;
    text-align: right;
    right: 2.5rem;
}

.clipboard-div-phone {
    position: absolute;
    width: 100%;
    text-align: right;
    right: 1rem;
}

.inspector-tree-div {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

.inspector-tree-div span {
    white-space: nowrap;
}

.react-table-centered-header {
    text-align: center;
}

#dep-app-list-select-all-button {
    background-color: #1c1c4d;
    white-space: nowrap;
    color: white;
}

.left-grid-device {
    display: 'flex';
    justify-content: center;
    padding-top: 24;
    padding-bottom: 20;
    position: 'sticky';
}

.multitouch-icons {
    padding-top: 0.70rem;
    padding-right: 0.70rem;
}


/* Chat App Attributes ---------------------------*/

.chat-portal-segment {
    left: 74%;
    position: fixed !important;
    height: 40vh;
    bottom: 0vh !important;
    display: flex;
	flex-direction: column;
    width: 25vw;
    padding-bottom: 1px !important;
    border-radius: 5px 5px 0px 0px !important;
	font-size: 0.8em !important;
    border: 1px solid #1C1C4D !important;
}

.chat-bot-header {
    height: 3vh !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3px;
    box-sizing: border-box;
    width: 100%;
}

.chat-bot-header-dropdown-div {
    display: flex; 
    justify-content: flex-start;
    align-items: start;
    width: 70%;
    margin-left: 0px;
}

.chat-bot-header-icons-div {
    display: flex; 
    justify-content: flex-end;
    align-items: flex-start;
    width: 20%;
    height: 100%;
}

.chat-bot-header-close {
    color: #1c1c4d;
    cursor: pointer;
}

/*
.ui.selection.dropdown.chat-app-device-id-dropdown{
    width: 60% !important;
    overflow: visible !important;
    text-overflow: ellipsis !important;
    max-width: 70;
}*/

.chat-form {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #D3D3D3;
    margin-top: auto;
    padding: 8px;
    padding-top: 10px;
}

.chat-input {
    align-self: flex-end;
    resize: none;
    max-height: 8.7vh;
    min-height: 4.5vh;
    border: none;
    border-radius: 5px;
    padding: 1%;
    width: 70%;
    font-size: 1.2em;
    background-color: #e3e6ef;
    padding-left: 1em;
    padding-right: 1em;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

.vertical-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat-msg-length {
    align-self: flex-end;
    height: 4.2vh;
}

.message-send-button {
    align-self: flex-end;
    color: #fff !important;
    background: #2185D0 !important;
    border: none;
    width: 17%;
    height: 4.5vh !important;
    border-radius: 5px;
    cursor: pointer;
}

.message-send-button:hover {
    color: #2185D0;
    box-shadow: 3px 3px 20px #2185D0, 3px 3px 20px #2185D0;
}

.message-send-button:disabled {
    opacity: 0.4;
    box-shadow: none !important;
    cursor: default;
}

.chat-bot-header-group-dropdown {
    background: white;
    color: rgb(41, 121, 255) !important;
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: flex-end !important;
    width: 10vw;
}

.messages {
    padding: 1% 0;
    overflow: auto;
    flex: auto;
    min-height: 25vh;
}

.chat-icon {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 2147483647 !important;
    color: #FE591D !important;
    cursor: pointer;
}

.message-container {
    display: flex;
    padding: 0 1%;
    margin-top: 3px;
}

.chat-message {
    position: relative;
    color: #4a4a4a;
    font-size: 14px;
    margin-top: 10px;
    border-radius: 6px;
    background-color: #ffffff;
    white-space: normal;
    width: fit-content;
    display: flex;
    border-radius: 10px;
    display: inline-block;
    max-width: 80%;
    padding: 0 5%;
}

.chat-name {
    top: -16px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    position: absolute;
    color: rgb(155, 153, 153);
}

.chat-message-box {
    display: flex;
    min-width: 70px;
}

.chat-message-box-text {
    /* max-width: 40vw; */
    word-break: break-word;
    margin-bottom: 20px;
    margin-right: 5px;
    background: #F3F3F3;
    border-radius: 20px;
    padding: 5px 10px;
    color: white;
    display: inline-block;
    max-width: 98%;
}

.message-text {
    width: 100%;
    letter-spacing: 0;
    float: left;
    font-size: 1.1em;
    word-wrap: break-word;
    margin-block: auto;
}

.background-light {
    background: #F3F3F3;
}

.color-dark {
    color: #353535;
}

.color-white {
    color: white;
}

.chat-timestamp {
    font-size: 10px;
    font-weight: 500;
    position: absolute;
    bottom: 2px;
    right: 3px;
    color: rgb(155, 153, 153);
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.background-blue {
    background: #2185D0;
}

#select-all-app-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* Device Info Attributes ---------------------------*/

.device-info-tab-content {
    padding: 2px 15px 0px 15px !important;
    margin-top: '20px';
    margin-bottom: '20px';
    min-height: calc(50vh - 100px);
}

.information-panel-tab {
    min-height: calc(50vh - 100px);
    margin: 20px 10px 20px 10px;
}


/* App Lib and File Library Pagination Stylings */

.library-pagination {
    width: 100%;
    text-align: center;
    margin-top: 23px;
    margin-bottom: 23px;
}

.float-right {
    float: right;
}

.defect-inspection-headerzone {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#segment-filled {
    overflow-y: auto;
    height: calc(50vh);
    max-height: calc(50vh);
    padding: 0;
}

#segment-default {
    overflow: auto;
    height: calc(50vh);
    max-height: calc(50vh);
    padding: 0;
}

#segment-content {
    margin-top: 5rem;
    margin-right: 1rem;
    margin-left: 1rem;
}

#segment-header {
    margin-left: 1.2rem;
    margin-top: 2rem;
}

.file-deploy-debounce {
    width: 95%;
}

.file-deploy-pagination {
    bottom: 0;
    position: relative;
}

.file-deploy-content {
    overflow-x: hidden !important;
    text-overflow: ellipsis !important;
    display: flex;
    flex-direction: row;
}

.file-detail-card {
    margin-bottom: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

#error-msg-styling {
    text-align: center;
    margin-top: 5rem
}

.deploy-content-span {
    margin-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.not-found-page-div{
    margin-top: 7rem;
    color: #1C1C4D !important;
    font-size: 70px !important;
    text-align: center;
}

.not-found-page-h1 {
    font-size: 20rem;
}

.ui.four.column.grid {
    flex-wrap: nowrap !important;
}

.main-left-container-fixed {
    width: 20rem;
}

.device-card-viewbar {
    display: flex;
    justify-content: flex-end;
    position: sticky !important;
    z-index: 1;
    top: 4.8rem;
    opacity: 1;
    background: white;
    padding: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
}

.filters-right{
    display: flex;
    text-align: center;
    flex-wrap: wrap;
}

.button-group-left{
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
}

#label-main-filter{
    margin-right: 5px;
    margin-top: 0.5rem;
}

#edit-form-buttons{
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

#edit-url-label{
    margin: 15px 10px 15px 0px;
    font-weight: bold;
}

.api-parameters{
    overflow-y: scroll;
    height: 40vh;
    margin-top: 2rem;
}

#integration-default-parameter{
    display: flex;
    flex-direction: row;
}

.integration-param-not-available {
    text-align: center !important;
}

#checkbox-parameter-div{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1.25rem;
}

#integration-edit-main-right-edit-button{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.integration-edit-plusbutton{
    margin-left: 10px !important;
}

#integration-edit-keyvalue{
    margin-right: 1.5rem;
}

#modal-checkbox{
    margin-bottom: 0.5rem;
}

#edit-url-field{
    display: flex;
    width: 50%;
}

#edit-modal-checkbox-div{
    display: block;
    margin: auto;
    text-align: center;
    width: 50%;
}

#edit-modal-topbar{
    display: flex;
    justify-content: center;
}

#integration-edit-segment{
    font-weight: bold;
    transition: background-color 0.5s;
}

#integration-edit-segment:hover{
    background-color: #9bcfef;
}

#edit-modal-checkbox-div label::before{
    background-color: red;
}


#edit-modal-checkbox-div input[type="checkbox"]:checked + label::before {
    background-color: green !important;
  }

.report-dropdown {
    word-break: break-all !important;
    width: 210px !important;
}

.instant-usage-usage-type-label {
    display: flex;
    color: gray;
    font-size: 12px;
}

.test-run-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.test-run-modal .result-section {
    flex-grow: 1;
}

.test-run-modal .device-section {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-left: 1rem;
}

#manage-folder-icon{
    margin-right: 0.30rem !important;
}

.manage-test-run-text-style{
    font-weight: 500 !important;
    font-size: 14px !important;
}

#manage-back-button{
    position: absolute;
    top: 0;
    left: 0;
    margin: 1rem
}

.manage-projects-header{
    text-align: center;
    margin-bottom: 1.5rem;
    margin-top: 1.25rem
}

.manage-test-runs-header{
    text-align: center;
    margin-bottom: 1.5rem;
    margin-top: 1.25rem;
}

#manage-projects-segment{
    margin-bottom: 1.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
}

#manage-test-run-segment{
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    justify-content: space-between;
    margin-right: 0.75rem;
    margin-left: 0.75rem;
}

.manage-test-run-accordion-title{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 2rem;
}

.manage-test-run-accordion-title-v2{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: -2rem;
}

#test-run-play-icon{
    height: 2.25rem;
    width: 2.25rem;
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
}

.test-run-modal-field{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#visiumManageLoginButton{
    display: block;
    margin: 0;
    margin-top: 40vh;
    margin-left: 5.5rem;
}

#test-run-panel-table{
    width: 20.5rem;
}

#filter-na-segment{
    display: flex;
    justify-content: center;
    width: 100%;
}

.library-file-upload-button{
    margin-top: 20px !important;
}

#group-reservation-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1vh;
    min-height: 5vh;
}

.groups-tab{
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

#groups-button{
    margin-right: 1rem;
    margin-top: 1rem;
}

#groups-modal-content{
    max-height: 300px;
    overflow-y: auto;
}

#list-of-groups{
    display: flex;
    justify-content:space-between;
    flex-wrap: wrap;
    margin-right: 0.75rem;
}

#column-style-group{
    flex-grow: 1;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px black;
    margin:5px;
    text-align:center;
    color: black;
    transition: color 0.3s ease-in-out;

    &:hover {
    background-color: lightgray;
    }

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.simulation-res-upload-modal{
    min-height: 25vh;
    img{
        object-fit: cover;
        margin-left: 2rem;
    }
}

#simulation-img-container {
    width: 40%
}
.simulation-res-upload-modal-desc {
    width: 60% !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    min-height: 30vh;
}

.simulation-res-upload-form {
    margin-top: 1.5rem;
}

.simulation-res-upload-form-field{
    margin-bottom: 0.2rem !important;
}

.simulation-res-upload-form label{
    margin-bottom: .5rem;
}
#simulation-res-upload-inf-grid{
    width:100%;
    height: 100%;
    display: flex;
    background-color: white;
    background-image:
            linear-gradient(to right, grey 1px, transparent 1px),
            linear-gradient(to bottom, grey 1px, transparent 1px);
    background-size: 15px 15px;
}
#simulation-res-upload-inf-label-container{
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
}
#simulation-res-upload-inf-label{
    background-color: whitesmoke !important;
    border-radius: 0.2rem !important;
}

#video-player-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

#media-viewer-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#media-viewer-container video {
    max-width: 90vw;
    max-height: 90vh;
}

#media-viewer-container img {
    max-width: 90vw;
    max-height: 90vh;
}
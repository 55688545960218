#languageDD {
  margin-left: 10px;
}

#collection-list:hover {
  margin-right: -10px;
}

#collection-list {
  position: fixed;
  width: calc(25% - 30px);
  height: calc(100vh - 70px);
  overflow-y: scroll;
  padding: 16px 16px 16px 48px;
  background-color: #f1f1f1;
  border-right: 1px solid #adadad;
  margin-top: -14px;
  margin-left: -16px;
}

.collection-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 20px;
}

.article-list-item {
  color: #000;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  margin-left: 10px;
}

.article-list-item:hover {
  border-left: 2px solid #FF5630;
  margin-left: 8px !important;
}

.article-list-item-active {
  border-left: 2px solid #FF5630;
  margin-left: 8px !important;
  background-color: rgba(232, 232, 232, 0.719);
}

.article-menu-subheader-list {
  list-style-type: none;
  margin: 5px 0 5px 8px !important;
  padding: 0 5px 0 15px !important;
}

.article-menu-subheader {
  cursor: pointer;
  font-size: 0.95em;
  padding-top: 4px;
  padding-bottom: 4px;
}

.article-menu-subheader:hover {
  background-color: rgba(232, 232, 232, 0.719);
}

#article-viewer {
  max-width: 740px;
  margin: 0 auto;
}

#article-title-header {
  margin-bottom: 25px;
  margin-top: 6px;
  font-size: 30px !important;
}

.ui.compact.item.dropdown>i.icon {
  margin-top: 10px;
  margin-left: 5px;
}

.image-tool__image-picture {
  max-width: 100%;
  display: block;
}

.image-tool__caption {
  text-align: center;
  border: none;
  box-shadow: none;
  padding-top: 0px;
  font-style: italic;
}

#article-viewer .cdx-block.image-tool.image-tool--filled {
  margin-top: 20px;
  margin-bottom: 20px;
}

#article-viewer .code-block {
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
  color: #41314e;
  line-height: 1.6em;
  font-size: 12px;
  background: #f8f7fa;
  border: 1px solid #f1f1f4;
  box-shadow: none;
  padding: 10px 12px;
  overflow: auto;
}

.cdx-block {
  padding: .4em 0;
}

#editorjs .tc-table__cell {
  text-align: center;
}

#editorjs .tc-table tr:first-child {
  font-weight: bold;
}

#article-viewer .tc-table__cell {
  padding: 10px;
  text-align: center;
}